import {combineReducers} from "redux";
import composeReducer from "./compose.reducer";
import addressBookReducer from "./addressbook.reducer";

const reducer = combineReducers({
  composeReducer,
  addressBook: addressBookReducer,
});

export default reducer;
