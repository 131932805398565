import localforage from "localforage";

const store = localforage.createInstance({
  driver: localforage.LOCALSTORAGE,
  name: "cache",
  version: 1.0,
});

const maxCacheLength = 100;

export const CACHE_SET_STATE = "CACHE_SET_STATE";
export const CACHE_ADD_MESSAGE = "CACHE_ADD_MESSAGE";
export const CACHE_LOADED = "CACHE_LOADED";
export const CACHE_RESET = "CACHE_RESET";

export const cacheStates = {
  enabled: "enabled",
  disabled: "disabled",
};

const getOldestMessages = messages => {
  const tmp = Object.values(messages);

  tmp.sort((a, b) => a.lastAccess - b.lastAccess);

  return tmp.slice(maxCacheLength);
};

export const setCacheState = state => (dispatch, getState) => {
  dispatch({
    type: CACHE_SET_STATE,
    payload: state,
  });

  const {data} = getState().auth.user;
  if (data && data.address) {
    store.setItem(`${data.address}.state`, state)
      .catch(error => {
          // eslint-disable-next-line no-console
        console.log(error);
      });
  }
};

export const addMessage = message => (dispatch, getState) => {
  const {state, cache} = getState().inboxData.cache;

  cache.messages[message.id] = {
    message,
    lastAccess: Date.now(),
  };

  if (Object.keys(cache.messages).length > maxCacheLength) {
    // clear oldest messages
    const oldestMessage = getOldestMessages(cache.messages);

    oldestMessage.forEach(m => {
      delete cache.messages[m.id];
    });
  }

  dispatch({
    type: CACHE_ADD_MESSAGE,
    payload: cache.messages,
  });

  // save to local storage if enabled
  if (state === cacheStates.enabled) {
    const {data} = getState().auth.user;
    if (data && data.address) {
      store.setItem(`${data.address}.cache`, JSON.stringify(cache))
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }
};

export const loadCache = () => async (dispatch, getState) => {
  const {data} = getState().auth.user;

  if (data && data.address) {
    try {
      const state = await store.getItem(`${data.address}.state`);
      const cache = await store.getItem(`${data.address}.cache`);

      dispatch({
        type: CACHE_LOADED,
        payload: {
          state,
          cache: cache
            ? JSON.parse(cache)
            : {
              version: 1,
              messages: {},
            },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const resetCache = () => ({type: CACHE_RESET});
