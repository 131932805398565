import React from "react";
import {Redirect} from "react-router-dom";
import i18next from "i18next";

import {authRoles} from "app/auth";
import en from "./i18n/en";
import fr from "./i18n/fr";

i18next.addResourceBundle("en", "inbox", en);
i18next.addResourceBundle("fr", "inbox", fr);

const RedirectToInbox = () => <Redirect to="/inbox" />;

RedirectToInbox.displayName = "RedirectToInbox";

const InboxConfig = {
  settings: {layout: "admin"},
  auth: authRoles.user,
  routes: [
    {
      path: "/",
      exact: true,
      component: RedirectToInbox,
    },
    {
      exact: true,
      path: "/inbox",
      component: React.lazy(() => import("./inbox")),
    },
    {
      exact: true,
      path: "/inbox/:messageId",
      component: React.lazy(() => import("./message")),
    },
  ],
};

export default InboxConfig;
