/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Invitation": "Invitation",
  "File request": "Demande de fichier ",
  "Recipient email": "Email du destinataire",
  "Message": "Message",
  "Reference": "Sujet",
  "Invite user": "Inviter l'utilisateur",
  "Send file request": "Envoyer la demande",
  "This text will be written in clear text in an email": "Ce contenu sera lisible en clair dans un email",
};

export default locale;
