/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Messages": "Messages",
  "Sender": "Contact",
  "Reference": "Sujet",
  "Sending time": "Date",
  "From": "De",
  "To": "À",
  "Attachment": "Attachement",
  "Reply": "Répondre",
  "Download file": "Télécharger le fichier",
  "Remove message": "Supprimer le message",
  "See older": "Voir précédent",
  "Previous": "Précédent",
  "Next": "Suivant",
  "Page": "Page",
  "rows": "lignes",
  "Outgoing message": "Message sortant",
  "Incoming message": "Message entrant",
};

export default locale;
