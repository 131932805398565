/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Login": "Connexion",
  "Inbox": "Messages",
  "Compose": "Envoyer",
  "Invitation": "Inviter par email",
  "English": "Anglais",
  "French": "Français",
  "Sign out": "Se déconnecter",
};

export default locale;
