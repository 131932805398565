import {
  Create,
  Mail,
  Share,
} from "@material-ui/icons";

const menuRoutes = [
  {
    path: "/inbox",
    name: "Inbox",
    icon: Mail,
  },
  {
    path: "/compose",
    name: "Compose",
    icon: Create,
  },
  {
    path: "/invitation",
    name: "Invitation",
    icon: Share,
  },
];

export default menuRoutes;
