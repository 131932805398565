import jamToast from "app/services/jamtoast";

export const INVITATION_RESET = "INVITATION_RESET";
export const INVITATION_ERROR = "INVITATION_ERROR";
export const INVITATION_SENDING = "INVITATION_SENDING";
export const INVITATION_SUCCESS = "INVITATION_SUCCESS";
export const INVITATION_SET_DATA = "INVITATION_SET_DATA";

export const setInvitationData = data => dispatch => dispatch({
  type: INVITATION_SET_DATA,
  payload: data,
});

export const invite = () => async (dispatch, getState) => {
  try {
    dispatch({type: INVITATION_SENDING});
    const {recipientEmail, message} = getState().invitation.invitation.data;

    const type = "simple";
    const res = await jamToast.inviteUser(recipientEmail, type, message);

    dispatch({
      type: INVITATION_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({type: INVITATION_ERROR});
    throw error;
  }
};

export const requestFile = () => async (dispatch, getState) => {
  try {
    dispatch({type: INVITATION_SENDING});
    const data = getState().invitation.invitation.data;
    const {recipientEmail, message, reference} = data;

    const type = "sendfile";
    const res = await jamToast.inviteUser(recipientEmail, type, message, reference);

    dispatch({
      type: INVITATION_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({type: INVITATION_ERROR});
    throw error;
  }
};

export const reset = () => dispatch => dispatch({type: INVITATION_RESET});
