import axios from "axios";
import {setProvider} from "@keeex/crypto";
import browerProvider from "@keeex/crypto-provider-browser";
import {Algorithms, digestString} from "@keeex/crypto/lib/digest";
import {deriveKeyRaw} from "@keeex/crypto/lib/keys";
import {ab2b64, ab2hex, str2ab} from "@keeex/js-utils/lib/arraybuffer";

import apiConfig from "app/configs/api.config";

setProvider(browerProvider);

const jamToastDomain = "https://jamtoast.beta.keeex.me";
const version = "v1";

const jamToastApi = axios.create({
  baseURL: `${jamToastDomain}/api/${version}`,
  headers: {"X-Requested-With": "XMLHttpRequest"},
  withCredentials: true,
});

const rounds = 1000;
const keyLen = 32;

export const derivePassword = async (email, password) => {
  const salt = str2ab(`${email}|login`);

  const derivedPassword = ab2hex(await deriveKeyRaw(
    password,
    salt,
    Algorithms.SHA256,
    rounds,
    keyLen,
  ));

  return derivedPassword;
};

export const setToken = accessToken => {
  jamToastApi.defaults.headers.common["X-Auth-Token"] = accessToken;
  // localStorage.setItem(apiConfig.jamtoastTokenKey, accessToken);
};

export const removeToken = () => {
  delete jamToastApi.defaults.headers.common["X-Auth-Token"];
  localStorage.removeItem(apiConfig.jamtoastTokenKey);
};

export const login = async (email, password) => {
  const derivedPassword = await derivePassword(email, password);
  const emailHash = ab2b64(await digestString(Algorithms.SHA256, email));

  const res = await jamToastApi.post("/auth/login", {
    emailHash,
    password: derivedPassword,
    version: 1,
  });
  if (!res.data.authToken) throw res.data.error;

  setToken(res.data.authToken);

  return res.data;
};

export const registerKey = async (publicKey, signature) => {
  const res = await jamToastApi.post("/auth/register/publicKey", {
    publicKey,
    signature,
  });
  if (!res.data.ok) throw res.data.error;
};

export const getVaultToken = async () => {
  const res = await jamToastApi.post("/auth/vault");
  if (!res.data.token) throw res.data.error;

  return res.data.token;
};

export const getAddress = async email => {
  const data = {emailHash: ab2b64(await digestString(Algorithms.SHA256, email))};
  const res = await jamToastApi.get("/users/getAddress", {params: data});
  if (!res.data.address) throw res.data.error;

  return res.data.address;
};

export const inviteUser = (recipientEmail, type, message, reference) => jamToastApi.post("/invite", {
  recipientEmail,
  type,
  message,
  reference,
});

export const addUser = async (email, password, publicKey) => {
  const derivedPassword = await derivePassword(email, password);
  const data = {
    email,
    password: derivedPassword,
    version: 1,
    publicKey,
    admin: false,
  };

  const res = await jamToastApi.post("/users", data);

  return res.data;
};

export const getInvite = async inviteCode => {
  const res = await jamToastApi.get("/auth/register/preinvite", {params: {inviteCode}});
  if (!res.data.type) throw res.data.error;

  return res.data;
};

export const registerInvite = async (email, password, inviteCode) => {
  const derivedPassword = await derivePassword(email, password);
  const res = await jamToastApi.post("/auth/register/invite", {
    inviteCode,
    password: derivedPassword,
    version: 1,
  });

  return res.data;
};

export const getConfig = async () => {
  const res = await jamToastApi.get("/config");

  return res.data;
};

export default {
  derivePassword,
  setToken,
  removeToken,
  login,
  registerKey,
  getVaultToken,
  getAddress,
  inviteUser,
  addUser,
  getInvite,
  registerInvite,
  getConfig,
};
