import LoginConfig from "./login/login.config";
import RegisterConfig from "./register/register.config";
import InvitationConfig from "./invitation/invitation.config";
import InboxConfig from "./inbox/inbox.config";
import ComposeConfig from "./compose/compose.config";

const mainConfigs = [
  LoginConfig,
  RegisterConfig,
  InvitationConfig,
  InboxConfig,
  ComposeConfig,
];

export default mainConfigs;
