/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Compose": "Compose",
  "Send message": "Send message",
  "Recipient email": "Recipient email",
  "Reference": "Subject",
  "Send file": "Send file",
  "Send text message": "Send text message",
};

export default locale;
