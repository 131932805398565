/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Invitation": "Invitation",
  "File request": "File request",
  "Recipient email": "Recipient email",
  "Message": "Message",
  "Reference": "Subject",
  "Invite user": "Invite user",
  "Send file request": "Send file request",
  "This text will be written in clear text in an email": "This text will be written in clear text in an email",
};

export default locale;
