import jam from "app/services/jam";
import jamToast from "app/services/jamtoast";

export const COMPOSE_RESET = "COMPOSE_RESET";
export const COMPOSE_ERROR = "COMPOSE_ERROR";
export const COMPOSE_SENDING = "COMPOSE_SENDING";
export const COMPOSE_SET_MODE = "COMPOSE_SET_MODE";
export const COMPOSE_SET_DATA = "COMPOSE_SET_DATA";
export const COMPOSE_SENT = "COMPOSE_SENT";

export const sendMessage = () => async (dispatch, getState) => {
  dispatch({type: COMPOSE_SENDING});

  const {mode, data} = getState().compose.composeReducer;

  const {email: senderEmail} = getState().auth.user.data;

  try {
    const recipientAddress = await jamToast.getAddress(data.recipientEmail);

    await jam.sendMessage({
      senderEmail,
      mode,
      data,
      recipientAddress,
      recipients: [data.recipientEmail],
    });

    dispatch({type: COMPOSE_SENT});
  } catch (error) {
    dispatch({type: COMPOSE_ERROR});
    throw error;
  }
};

export const setMode = mode => ({
  type: COMPOSE_SET_MODE,
  payload: mode,
});

export const setData = data => ({
  type: COMPOSE_SET_DATA,
  payload: data,
});

export const reset = () => ({type: COMPOSE_RESET});
