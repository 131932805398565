import jam from "app/services/jam";
import {str2ab, ab2str} from "@keeex/js-utils/lib/arraybuffer";

export const ADDRESSBOOK_SET_ADDRESSES = "ADDRESSBOOK_SET_ADDRESSES";
export const ADDRESSBOOK_IMPORT_ADDRESSBOOK = "ADDRESSBOOK_IMPORT_ADDRESSBOOK";

const addressBookLabel = "toast.addressbook";

export const addAddress = email => (dispatch, getState) => {
  const addressBook = getState().compose.addressBook;

  if (!addressBook.addresses.includes(email)) {
    addressBook.addresses.push(email);

    dispatch({
      type: ADDRESSBOOK_SET_ADDRESSES,
      payload: addressBook.addresses,
    });

    jam.saveStorageData({
      label: addressBookLabel,
      data: str2ab(JSON.stringify(addressBook)),
    })
      .catch(error => {
          // eslint-disable-next-line no-console
        console.log(error);
      });
  }
};

export const importAddresses = () => dispatch => {
  jam.getStorageData(addressBookLabel)
    .then(data => {
      const value = JSON.parse(ab2str(data));
      if (value) {
        dispatch({
          type: ADDRESSBOOK_IMPORT_ADDRESSBOOK,
          payload: value,
        });
      }
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};
