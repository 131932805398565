import * as reduxModule from "redux";
import thunk from "redux-thunk";
import {persistStore} from "redux-persist";
import rootReducer from "./reducers";

const {applyMiddleware, compose, createStore} = reduxModule;

/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

const composeEnhancers
  // eslint-disable-next-line no-process-env
  = process.env.NODE_ENV !== "production"
  && typeof window === "object"
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, enhancer);

const persistor = persistStore(store);

export {store, persistor};
