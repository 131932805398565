/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Login": "Login",
  "Inbox": "Messages",
  "Compose": "Send",
  "Invitation": "Invite by email",
  "English": "English",
  "French": "French",
  "Sign out": "Sign out",
};

export default locale;
