import {combineReducers} from "redux";
import {createMigrate, persistReducer} from "redux-persist";
import localForage from "localforage";

import auth from "app/auth/store/reducers";
import compose from "app/main/compose/store/reducers";
import inboxData from "app/main/inbox/store/reducers";
import invitation from "app/main/invitation/store/reducers";

import settingsReducer, {migrations} from "./settings.reducer";

const MIGRATION_DEBUG = false;

const settingsPersistConfig = {
  key: "settingsStore",
  version: 0,
  storage: localForage,
  migrate: createMigrate(migrations, {debug: MIGRATION_DEBUG}),
};

const rootReducer = combineReducers({
  auth,
  compose,
  inboxData,
  invitation,
  settings: persistReducer(settingsPersistConfig, settingsReducer),
});

export default rootReducer;
