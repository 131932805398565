import React from "react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {Router} from "react-router-dom";

import _history from "@history";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import "app/i18n/i18n.config";

import Authorization from "app/auth/authorization";
import Layout from "app/layout/layout";

import AppContext from "./app_context";
import routes from "./configs/routes.config";
import {store, persistor} from "./store";
import {Auth} from "./auth";

const App = () => (
  <AppContext.Provider value={{routes}}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Auth>
          <Router history={_history}>
            <Authorization>
              <Layout />
            </Authorization>
          </Router>
        </Auth>
      </PersistGate>
    </Provider>
  </AppContext.Provider>
);

App.displayName = "App";

export default App;
