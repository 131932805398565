import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth";
import en from "./i18n/en";
import fr from "./i18n/fr";

i18next.addResourceBundle("en", "register", en);
i18next.addResourceBundle("fr", "register", fr);

const RegisterConfig = {
  settings: {layout: "auth"},
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/auth/register",
      exact: true,
      component: React.lazy(() => import("./register")),
    },
  ],
};

export default RegisterConfig;
