/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Login": "Login",
  "Email": "Email",
  "Password": "Password",
  "Required fields": "Required fields",
};

export default locale;
