/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Messages": "Messages",
  "Sender": "Contact",
  "Reference": "Subject",
  "Sending time": "Date",
  "From": "From",
  "To": "To",
  "Attachment": "Attachment",
  "Reply": "Reply",
  "Download file": "Download file",
  "Remove message": "Remove message",
  "See older": "See previous",
  "Previous": "Previous",
  "Next": "Next",
  "Page": "Page",
  "rows": "rows",
  "Outgoing message": "Outgoing message",
  "Incoming message": "Incoming message",
};

export default locale;
