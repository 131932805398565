import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth";
import en from "./i18n/en";
import fr from "./i18n/fr";

i18next.addResourceBundle("en", "invitation", en);
i18next.addResourceBundle("fr", "invitation", fr);

const InvitationConfig = {
  settings: {layout: "admin"},
  auth: authRoles.user,
  routes: [
    {
      path: "/invitation",
      exact: true,
      component: React.lazy(() => import("./invitation")),
    },
  ],
};

export default InvitationConfig;
