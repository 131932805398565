/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Login": "Connexion",
  "Email": "Mail",
  "Password": "Mot de passe",
  "Required fields": "Champs obligatoires",
};

export default locale;
