import {addAddress} from "app/main/compose/store/actions";
import jamService from "../../../../services/jam";
import {addMessage} from "./cache.action";

export const INBOX_RESET = "INBOX_RESET";
export const INBOX_ERROR = "INBOX_ERROR";
export const INBOX_FETCHING = "INBOX_FETCHING";
export const INBOX_FETCH_MESSAGES = "INBOX_FETCH_MESSAGES";
export const INBOX_DELETE_MESSAGES = "INBOX_DELETE_MESSAGES";
export const INBOX_SET_INBOX_SEARCH = "INBOX_SET_INBOX_SEARCH";
export const INBOX_SET_UNREAD_MESSAGES = "INBOX_SET_UNREAD";

export const setInboxSearch = data => dispatch => {
  dispatch({
    type: INBOX_SET_INBOX_SEARCH,
    payload: data,
  });
};

export const deleteMessage = (mailId, messages) => async dispatch => {
  dispatch({type: INBOX_FETCHING});

  try {
    await jamService.deleteMessage(mailId);

    const arr = messages.filter(element => element.id !== mailId);
    dispatch({
      type: INBOX_DELETE_MESSAGES,
      payload: arr,
    });
  } catch (error) {
    dispatch({
      type: INBOX_ERROR,
      payload: error,
    });

    throw error;
  }
};

const buildMessages = (dispatch, messages, cache) => {
  const promises = messages.map(async message => {
    const {
      metadata,
      encryptedMetadata,
    } = cache.messages[message.id]
      ? await jamService.decryptMetadata(
        cache.messages[message.id].message.encryptedMetadata,
      )
      : await jamService.getMetaData(message.id);

    if (encryptedMetadata) {
      dispatch(addMessage({...message, encryptedMetadata}));

      if (metadata && metadata.sender) {
        dispatch(addAddress(metadata.sender));
      }
    }

    return {
      ...message,
      metadata,
    };
  });

  return Promise.all(promises);
};

export const fetchMessages = () => async (dispatch, getState) => {
  dispatch({type: INBOX_FETCHING});

  const {page, pageSize} = getState().inboxData.inbox.inbox;
  const {cache} = getState().inboxData.cache;

  try {
    const res = await jamService.getMessages({page, pageSize});

    let messages = [];
    if (res.data.messages && res.data.messages.length) {
      messages = await buildMessages(dispatch, res.data.messages, cache);
    }

    const payload = {
      ...res.data,
      totalPages: Math.ceil(res.data.total / pageSize),
      messages,
    };

    dispatch({type: INBOX_FETCH_MESSAGES, payload});

    return res;
  } catch (error) {
    dispatch({
      type: INBOX_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const autoFetchMessages = () => async (dispatch, getState) => {
  const {page, pageSize, messages} = getState().inboxData.inbox.inbox;
  const {cache} = getState().inboxData.cache;

  try {
    if (page === 0 && messages.length > 0) {
      dispatch({type: INBOX_FETCHING});
      const res = await jamService.getMessages({
        messageId: messages[0].id,
        page,
        pageSize,
      });

      let fetchedMessages = [];
      if (res.data.messages && res.data.messages.length) {
        fetchedMessages = await buildMessages(dispatch, res.data.messages, cache);
      }

      const payload = {
        messages: [
          ...fetchedMessages,
          ...messages.slice(0, pageSize - fetchedMessages.length),
        ],
      };

      dispatch({type: INBOX_FETCH_MESSAGES, payload});
    }
  } catch (error) {
    dispatch({
      type: INBOX_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const markAsRead = messageId => (dispatch, getState) => {
  const {inbox} = getState().inboxData.inbox;

  const m = inbox.messages.find(e => e.id === messageId);
  if (m && !m.read) {
    m.read = true;
    dispatch({
      type: INBOX_FETCH_MESSAGES,
      payload: {...inbox},
    });
  }
};

export const reset = () => dispatch => dispatch({type: INBOX_RESET});

export const countUnread = () => async dispatch => {
  const res = await jamService.getMessages({
    unread: true,
    page: 0,
    pageSize: 1,
  });

  dispatch({
    type: INBOX_SET_UNREAD_MESSAGES,
    payload: res.data.total,
  });
};
