import {ab2str} from "@keeex/js-utils/lib/arraybuffer";
import {addAddress} from "app/main/compose/store/actions";

import jamService from "../../../../services/jam";
import {addMessage} from "./cache.action";
import {markAsRead} from "./inbox.action";

export const MESSAGE_SET = "MESSAGE_SET";
export const MESSAGE_ERROR = "MESSAGE_ERROR";
export const MESSAGE_FETCH_OLDER = "MESSAGE_FETCH_OLDER";

export const setMessage = message => async dispatch => {
  dispatch({
    type: MESSAGE_SET,
    payload: [message],
  });

  if (message
    && message.metadata
    && message.metadata.extensions
    && message.metadata.extensions.text_message) {
    // get text data
    const textData = await jamService.getData(message.id);

    const loadedMessage = {
      ...message,
      text: ab2str(textData),
    };

    dispatch({
      type: MESSAGE_SET,
      payload: [loadedMessage],
    });

    dispatch(markAsRead(message.id));
  }
};

export const fetchMessage = messageId => async (dispatch, getState) => {
  const {cache} = getState().inboxData.cache;

  try {
    const {metadata, encryptedMetadata} = cache.messages[messageId]
      ? await jamService.decryptMetadata(
        cache.messages[messageId].message.encryptedMetadata,
      )
      : await jamService.getMetaData(messageId);

    dispatch(setMessage({id: messageId, metadata}));

    if (encryptedMetadata) {
      if (metadata && metadata.sender) {
        dispatch(addAddress(metadata.sender));
      }

      dispatch(addMessage({id: messageId, encryptedMetadata}));
    }
  } catch (error) {
    dispatch({type: MESSAGE_ERROR, payload: error});

    throw error;
  }
};

export const fetchOlder = () => async (dispatch, getState) => {
  try {
    const {messages} = getState().inboxData.message;
    const {cache} = getState().inboxData.cache;

    const lastMessage = messages.length ? messages[messages.length - 1] : null;
    if (lastMessage
      && lastMessage.metadata
      && lastMessage.metadata.extensions
      && lastMessage.metadata.extensions.text_message
      && lastMessage.metadata.extensions.text_message.replyTo) {
      const messageId = lastMessage.metadata.extensions.text_message.replyTo;

      const {metadata, encryptedMetadata} = cache.messages[messageId]
        ? await jamService.decryptMetadata(
          cache.messages[messageId].message.encryptedMetadata,
        )
        : await jamService.getMetaData(messageId);

      if (encryptedMetadata) {
        if (metadata && metadata.sender) {
          dispatch(addAddress(metadata.sender));
        }

        dispatch(addMessage({id: messageId, encryptedMetadata}));
      }

      const payload = [
        ...messages,
        {id: messageId, metadata},
      ];
      dispatch({type: MESSAGE_SET, payload});

      if (metadata.extensions && metadata.extensions.text_message) {
        // get text data
        const textData = await jamService.getData(messageId);

        const loadedMessages = [
          ...messages,
          {id: messageId, metadata, text: ab2str(textData)},
        ];

        dispatch({
          type: MESSAGE_SET,
          payload: loadedMessages,
        });
      }
    }
  } catch (error) {
    dispatch({type: MESSAGE_ERROR, payload: error});

    throw error;
  }
};
