import * as Actions from "../actions";
import RichTextEditor from "react-rte";

const initialState = () => ({
  error: null,
  mode: "file",
  data: {
    replyTo: "",
    recipientEmail: "",
    reference: "",
    file: "",
    text: RichTextEditor.createEmptyValue(),
  },
  sending: false,
});

// eslint-disable-next-line default-param-last
const composeReducer = (state = initialState(), action) => {
  switch (action.type) {
  case Actions.COMPOSE_SET_MODE:
    return {
      ...state,
      mode: action.payload,
    };
  case Actions.COMPOSE_SET_DATA:
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
    };
  case Actions.COMPOSE_SENDING:
    return {
      ...state,
      sending: true,
    };
  case Actions.COMPOSE_ERROR:
    return {
      ...state,
      sending: false,
      error: action.payload,
    };
  case Actions.COMPOSE_RESET:
    return initialState();
  default:
    return state;
  }
};

export default composeReducer;
