import React, {useCallback} from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Fingerprint from "@material-ui/icons/Fingerprint";

// core components
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";
import {useDispatch, useSelector} from "react-redux";

import * as Actions from "app/store/actions/settings.action";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(styles);

// eslint-disable-next-line max-lines-per-function
const AuthNavbar = props => {
  const [_open, setOpen] = React.useState(false);
  const {t} = useTranslation("common");
  const dispatch = useDispatch();
  const language = useSelector(({settings}) => settings.language);

  const handleENClicked = useCallback(() => {
    dispatch(Actions.setLanguage("EN"));
  }, [dispatch]);

  const handleFRClicked = useCallback(() => {
    dispatch(Actions.setLanguage("FR"));
  }, [dispatch]);

  const handleDrawerToggle = useCallback(() => {
    setOpen(!_open);
  }, [_open]);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => window.location.href.indexOf(routeName) > -1;

  const classes = useStyles();
  const {color, brandText} = props;
  const appBarClasses = cx({[` ${classes[color]}`]: color});
  const list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          onClick={handleENClicked}
          className={cx(classes.navLink, {[classes.navLinkActive]: language === "EN"})}
          tabIndex={-1}
        >
          <ListItemText
            primary={"EN"}
            disableTypography
            className={classes.listItemText}
          />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          onClick={handleFRClicked}
          className={cx(classes.navLink, {[classes.navLinkActive]: language === "FR"})}
          tabIndex={-1}
        >
          <ListItemText
            primary={"FR"}
            disableTypography
            className={classes.listItemText}
          />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/auth/login"}
          className={cx(classes.navLink, {[classes.navLinkActive]: activeRoute("/auth/login")})}
          tabIndex={-1}
        >
          <Fingerprint className={classes.listItemIcon} />
          <ListItemText
            primary={t("Login")}
            disableTypography
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent" tabIndex={-1}>
              {brandText}
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent" tabIndex={-1}>
              {brandText}
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            tabIndex={-1}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={_open}
              classes={{paper: classes.drawerPaper}}
              onClose={handleDrawerToggle}
              ModalProps={{keepMounted: true}}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

AuthNavbar.displayName = "AuthNavbar";

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};

AuthNavbar.defaultProps = {
  color: "primary",
  brandText: "",
};

export default AuthNavbar;
