import * as Actions from "../actions";

const initialState = () => ({
  error: null,
  messages: [],
});

// eslint-disable-next-line default-param-last
const messageReducer = (state = initialState(), action) => {
  switch (action.type) {
  case Actions.MESSAGE_SET:
    return {
      ...state,
      messages: action.payload,
    };
  case Actions.MESSAGE_ERROR:
    return {
      ...state,
      error: action.payload,
    };
  default:
    return state;
  }
};

export default messageReducer;
