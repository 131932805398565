import * as Actions from "../actions";
const initialState = () => ({
  loaded: false,
  state: null,
  cache: {
    version: 1,
    messages: {},
  },
});

// eslint-disable-next-line default-param-last
const cacheReducer = (state = initialState(), action) => {
  switch (action.type) {
  case Actions.CACHE_SET_STATE:
    return {
      ...state,
      state: action.payload,
    };
  case Actions.CACHE_ADD_MESSAGE:
    return {
      ...state,
      cache: {
        ...state.cache,
        messages: action.payload,
      },
    };
  case Actions.CACHE_LOADED:
    return {
      ...state,
      ...action.payload,
      loaded: true,
    };
  case Actions.CACHE_RESET:
    return initialState();
  default:
    return state;
  }
};

export default cacheReducer;
