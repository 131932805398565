import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

const Loading = props => {
  const [showLoading, setShowLoading] = useState(!props.delay);

  useEffect(() => {
    setShowLoading(true);
  }, []);

  if (!showLoading) {
    return null;
  }

  return (
    <div className="page-loader">
      <img
        width={128}
        className="loading-img"
        src="/app/assets/logo/logo_128.png"
        alt="KeeeX JamToast"
      />
    </div>
  );
};

Loading.displayName = "Loading";

Loading.propTypes = {delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])};

Loading.defaultProps = {delay: false};

export default Loading;
