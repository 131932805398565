const domain = "https://jam.beta.keeex.me";
const version = "v1";

const baseURL = `${domain}/api/${version}`;
const jamtoastTokenKey = "jamtoast_access_token";
const jamTokenKey = "jam_access_token";
const userKey = "user_key";

const apiConfig = {
  baseURL,
  jamtoastTokenKey,
  jamTokenKey,
  userKey,
};

export default apiConfig;
