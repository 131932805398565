import {setCryptoProvider} from "@keeex/js-keys";
import cryptoProvider from "@keeex/crypto-provider-browser";
import {ab2str, ab2hex, str2ab} from "@keeex/js-utils/lib/arraybuffer";

import {
  generateKey,
  importKey,
  KeyType,
} from "@keeex/js-keys/lib/keys";

import Vault from "@keeex/js-vaultsdk";

setCryptoProvider(cryptoProvider);

class KeyService {
  constructor() {
    this._key = null;
    this._vault = Vault.createUserVault(
      "https://jamtoast.beta.keeex.me/api/v1/auth/vault",
      true,
    );
  }

  get key() {
    return this._key;
  }

  get hasKey() {
    return (this._key !== null);
  }

  get type() {
    return this._key ? this._key.getType() : null;
  }

  get address() {
    return this._key ? this._key.getAddress() : null;
  }

  get publicKey() {
    return this._key ? this._key.getPublic() : null;
  }

  clear() {
    this._key = null;
  }

  async generateKey() {
    const key = await generateKey(KeyType.bitcoin);
    this._key = key;

    return key;
  }

  _exportKey() {
    return this._key.exportKey(false, {type: "json"});
  }

  async exportKey(password) {
    if (!this._key) return null;

    if (password) {
      const securedKey = await this._key.exportKey(
        false,
        {
          type: "seal",
          password,
        },
      );

      return Buffer.from(securedKey).toString();
    }

    const exportedKey = await this._key.exportKey(false, {type: "json"});

    return Buffer.from(exportedKey).toString();
  }

  async getPublic() {
    if (!this._key) return null;

    return ab2hex(await this._key.getPublic());
  }

  async _importKey(value) {
    this._key = await importKey(KeyType.bitcoin, {type: "json", value});
  }

  async importKey(password, keyData) {
    if (password) {
      this._key = await importKey(KeyType.bitcoin, {
        type: "seal",
        password,
        value: keyData,
      });
    } else {
      this._key = await importKey(KeyType.bitcoin, {
        type: "json",
        value: keyData,
      });
    }
  }

  async sign(message) {
    if (!this._key) return null;

    return ab2str(await this._key.sign(str2ab(message)));
  }

  register(password, token) {
    if (!this._key) return null;

    this._vault.addHeader("X-Auth-Token", token);

    return this._vault.registerKxKey(this._key, password);
  }

  async getBlob(password, token, publicKey) {
    this._vault.addHeader("X-Auth-Token", token);
    const sealedKey = await this._vault.getBlob(publicKey, password);

    await this.importKey(password, sealedKey);
  }

  /**
   * Sign list of messages
   *
   * @param { Array<?> } list of messages
   *
   * @returns { Object<message, signature> }
   */
  async signMessages(messages) {
    const signatures = {};

    for (const message of messages) {
      // eslint-disable-next-line no-await-in-loop
      signatures[message] = await this.sign(message);
    }

    return signatures;
  }

  /**
   * Sign list of pending files returned from Keeex Chains
   *
   * @param {string[]} pendingFiles
   * @param {Object} pendingFiles[].kxdata
   * @param {String} pendingFiles[].kxdata.idx
   *
   * @returns { Object }
   */
  signPendingFiles(pendingFiles) {
    const convertedFiles = pendingFiles.map(item => {
      const obj = JSON.parse(Buffer.from(item, "base64"));
      return obj;
    });

    const messages = convertedFiles.map(file => file.kxdata.idx);

    return this.signMessages(messages);
  }
}

const _instance = new KeyService();

export default _instance;
