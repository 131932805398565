import React, {createRef, useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";

import cx from "classnames";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import menuRoutes from "app/configs/menu.config";
import {useTranslation} from "react-i18next";

let ps;

const useStyles = makeStyles(styles);

const innerWidthBreak = 960;
const color = "blue";
const bgColor = "black";
const logo = "/app/logo-192.png";
const timeInterval = 30000;

import * as Actions from "app/main/inbox/store/actions";

// eslint-disable-next-line max-lines-per-function
const AdminLayout = props => {
  const {t} = useTranslation("common");
  const dispatch = useDispatch();

  const {...rest} = props;
  // states and functions
  const [routes, setRoutes] = useState(menuRoutes);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);

  const user = useSelector(({auth}) => auth.user);
  const {unreadMessages} = useSelector(({inboxData}) => inboxData.inbox);

  const resizeFunction = () => {
    if (window.innerWidth >= innerWidthBreak) {
      setMobileOpen(false);
    }
  };

  // styles
  const classes = useStyles();
  const mainPanelClasses
    = `${classes.mainPanel
    } ${
      cx({
        [classes.mainPanelSidebarMini]: miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
      })}`;
  // ref for main panel div
  const mainPanel = createRef();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  useEffect(() => {
    if (user.data) {
      dispatch(Actions.countUnread());

      const inteval = setInterval(() => {
        dispatch(Actions.countUnread());
      }, timeInterval);

      return () => {
        clearInterval(inteval);
      };
    }
  }, [dispatch, user.data]);

  useEffect(() => {
    if (unreadMessages !== routes[0].badge) {
      routes[0].badge = unreadMessages;
      setRoutes([...routes]);
    }
  }, [routes, unreadMessages]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(m => !m);
  }, []);

  const sidebarMinimize = useCallback(() => {
    setMiniActive(m => !m);
  }, []);

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>
          {`${
            unreadMessages > 0 ? `(${unreadMessages}) ` : ""
          }${
            user.data ? `${user.data.email} - ` : ""
          }KeeeX JAMToast`}
        </title>
      </Helmet>
      <Sidebar
        routes={routes}
        logoText={"Jam Toast"}
        logo={logo}
        t={t}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={""}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>{props.children}</div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};

AdminLayout.displayName = "AdminLayout";

AdminLayout.propTypes = {children: PropTypes.node.isRequired};

export default AdminLayout;
