import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import * as userActions from "app/auth/store/actions";
import jwtService from "app/services/jwt";

const SplashScreen = () => (
  <div className="page-loader">
    <img
      width={128}
      className="loading-img"
      src="/app/assets/logo/logo_128.png"
      alt="KeeeX JamToast"
    />
  </div>
);

SplashScreen.displayName = "SplashScreen";

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {waitAuthCheck: true};
  }

  componentDidMount() {
    return Promise.all([
      // Comment the lines which you do not use
      this.jwtCheck(),
    ]).then(() => {
      this.setState({waitAuthCheck: false});
    });
  }

  jwtCheck() {
    return new Promise(resolve => {
      jwtService.on("onAutoLogin", () => {
        resolve();
        // jwtService
        //   .signInWithToken()
        //   .then(user => {
        //     this.props.setUserData(user);
        //     resolve();
        //   })
        //   .catch(() => {
        //     resolve();
        //   });
      });

      jwtService.on("onAutoLogout", message => {
        if (message) {
          // this.props.showMessage({ message });
        }

        this.props.logout();

        resolve();
      });

      jwtService.on("onNoAccessToken", () => {
        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });
  }

  render() {
    return this.state.waitAuthCheck ? (
      <SplashScreen />
    ) : (
      // <>{this.props.children}</>
      this.props.children
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    logout: userActions.logoutUser,
    setUserData: userActions.setUserData,
  },
  dispatch,
);

Auth.displayName = "Auth";

Auth.defaultProps = {children: null};

Auth.propTypes = {
  setUserData: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default connect(null, mapDispatchToProps)(Auth);
