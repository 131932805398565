import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {renderRoutes, matchRoutes} from "react-router-config";
import AppContext from "app/app_context";
import {useLocation} from "react-router-dom";

import Suspense from "./components/suspense";
import AuthLayout from "./auth";
import AmdinLayout from "./admin";
import {useSelector} from "react-redux";

import i18n from "../../i18n";

const Layout = props => {
  const appContext = useContext(AppContext);
  const {routes} = appContext;
  const _location = useLocation();
  const {pathname} = _location;
  const matched = matchRoutes(routes, pathname)[0];
  const language = useSelector(({settings}) => settings.language);

  let layout = "auth";
  if (matched && matched.route.settings) {
    const routeSettings = matched.route.settings;
    if (routeSettings.layout) {
      layout = routeSettings.layout;
    }
  }

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language.toLowerCase());
    }
  }, [language]);

  return (
    <div>
      <Suspense>
        {layout === "admin" ? (
          <AmdinLayout>{renderRoutes(routes)}</AmdinLayout>
        ) : (
          <AuthLayout>{renderRoutes(routes)}</AuthLayout>
        )}
      </Suspense>

      {props.children}
    </div>
  );
};

Layout.displayName = "Layout";

Layout.defaultProps = {children: null};

Layout.propTypes = {children: PropTypes.node};

export default React.memo(Layout);
