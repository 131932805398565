/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Register": "Register",
  "You have been invited to use JamToast application": "You have been invited to use JamToast application",
  "Please setup your password to start using JamToast": "Please setup your password to start using JamToast",
  "Email": "Email",
  "Password": "Password",
  "Confirm password": "Confirm password",
  "Required fields": "Required fields",
  "GotoLogin": "If you have already registered, go to <1>login page</1>",
};

export default locale;
