import jwtService from "app/services/jwt";
import jamToast from "app/services/jamtoast";

import * as UserActions from "./user.action";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SET_DATA = "LOGIN_SET_DATA";

export const submitLogin = ({email, password}) => dispatch => jwtService
  .signInWithEmailAndPassword(email, password)
  .then(user => {
    dispatch(UserActions.setUserData(user));

    dispatch({type: LOGIN_SUCCESS});

    return user;
  })
  .catch(error => {
    dispatch({
      type: LOGIN_ERROR,
      payload: error,
    });

    throw error;
  });

export const submitRegister = ({email, password, inviteToken}) => dispatch => jamToast
  .registerInvite(email, password, inviteToken)
  .catch(error => {
    dispatch({
      type: LOGIN_ERROR,
      payload: error,
    });

    throw error;
  });

export const setData = data => ({
  type: LOGIN_SET_DATA,
  payload: data,
});
