import axios from "axios";
import {setProvider} from "@keeex/crypto";
import browerProvider from "@keeex/crypto-provider-browser";
import {Algorithms, digestImmediate} from "@keeex/crypto/lib/digest";
import {importKey, KeyType} from "@keeex/js-keys/lib/keys";
import {Bundle} from "@keeex/js-keys/lib/bundle";
import {str2ab, ab2str, hex2ab, ab2b64, b642ab} from "@keeex/js-utils/lib/arraybuffer";
import _ from "lodash";

import apiConfig from "app/configs/api.config";

setProvider(browerProvider);

const jamDomain = "https://jam.beta.keeex.me";
const version = "v1";

const jamApi = axios.create({
  baseURL: `${jamDomain}/api/${version}`,
  headers: {"X-Requested-With": "XMLHttpRequest"},
});

let _key = null;

export const setTokenKey = (accessToken, key) => {
  jamApi.defaults.headers.common.Authorization = `JWT ${accessToken}`;
  _key = key;

  // const keyData = await key.exportKey(false, {type: "json"});
  // localStorage.setItem(apiConfig.jamTokenKey, accessToken);
  // localStorage.setItem(apiConfig.userKey, JSON.stringify(ab2str(keyData)));
};

export const removeTokenKey = () => {
  delete jamApi.defaults.headers.common.Authorization;
  _key = null;

  localStorage.removeItem(apiConfig.jamTokenKey);
  localStorage.removeItem(apiConfig.userKey);
};

export const signinWithKey = async key => {
  let res = await jamApi.get("/auth/challenge", {params: {address: key.getAddress()}});
  const {challenge} = res.data.data;

  const signature = ab2str(await key.sign(str2ab(challenge)));
  const data = {challenge, signature};
  res = await jamApi.post("/auth/challenge", data);

  setTokenKey(res.data.data.authToken, key);

  return res.data.data.authToken;
};

export const registerKey = async (publicKey, emailSig) => {
  const data = {
    publicKey,
    signature: emailSig,
  };
  const res = await jamApi.post("/auth/register/publicKey", data);

  return res.data;
};

export const getFileBuffer = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.onerror = reject;

  reader.readAsArrayBuffer(file);
});

export const buildFileMetadata = async ({
  sender,
  recipients,
  reference,
  file,
}) => {
  const dataBuff = await getFileBuffer(file);
  const hash = await digestImmediate(Algorithms.SHA256, dataBuff);
  const plainHash = ab2b64(hash);
  const signature = ab2str(await _key.sign(hash));

  return {
    sender,
    to: recipients,
    name: encodeURI(file.name),
    reference: encodeURI(reference),
    plainHashType: "sha256",
    plainHash,
    signature,
  };
};

export const buildTextMetadata = async ({
  sender,
  recipients,
  replyTo,
  reference,
  data,
}) => {
  const dataBuff = str2ab(data);
  const hash = await digestImmediate(Algorithms.SHA256, dataBuff);
  const plainHash = ab2b64(hash);
  const signature = ab2str(await _key.sign(hash));

  return {
    sender,
    to: recipients,
    reference: encodeURI(reference),
    plainHashType: "sha256",
    plainHash,
    signature,
    extensions: {
      "text_message": {
        replyTo,
        format: "markdown",
      },
    },
  };
};

export const getPublicKey = async (address, config) => {
  const res = await jamApi.get(`/publickey/${address}`, config);
  return res.data.data.publicKey;
};

// eslint-disable-next-line max-lines-per-function
export const sendMessage = async ({
  senderEmail,
  mode,
  data,
  recipientAddress,
  recipients,
}) => {
  const formData = new FormData();
  const config = {headers: {"content-type": `multipart/form-data; boundary=${formData._boundary}`}};

  const recipientPublicKey = await getPublicKey(recipientAddress, config);

  const recipientKey = await importKey(
    KeyType.bitcoin,
    {
      type: "publicKey",
      publicKey: hex2ab(recipientPublicKey),
    },
  );

  if (mode === "file" && data.file) {
    const dataBuff = await getFileBuffer(data.file);
    const metadata = await buildFileMetadata({
      sender: senderEmail,
      recipients,
      reference: data.reference,
      file: data.file,
    });
    const metadataBuff = str2ab(JSON.stringify(metadata));

    const bigBundle = await Bundle.createBundle(
      [_key, recipientKey],
      {
        data: dataBuff,
        metadata: metadataBuff,
      },
    );

    const encryptedDataBundle = await bigBundle.saveBundle(["data"]);
    const encryptedMetadataBundle = await bigBundle.saveBundle(["metadata"]);
    formData.append(
      "data",
      new Blob([encryptedDataBundle], {type: "application/octet-stream"}),
    );
    formData.append(
      "metadata",
      new Blob([encryptedMetadataBundle], {type: "application/octet-stream"}),
    );

    formData.append("scope", "jamInbox");
  } else if (mode === "text" && data.text) {
    const dataBuff = str2ab(data.text.toString("markdown"));
    const metadata = await buildTextMetadata({
      sender: senderEmail,
      recipients,
      replyTo: data.replyTo,
      reference: data.reference,
      data: data.text.toString("markdown"),
    });
    const metadataBuff = str2ab(JSON.stringify(metadata));
    const bigBundle = await Bundle.createBundle(
      [_key, recipientKey],
      {
        data: dataBuff,
        metadata: metadataBuff,
      },
    );
    const encryptedDataBundle = await bigBundle.saveBundle(["data"]);
    const encryptedMetadataBundle = await bigBundle.saveBundle(["metadata"]);
    formData.append(
      "data",
      new Blob([encryptedDataBundle], {type: "application/octet-stream"}),
    );
    formData.append(
      "metadata",
      new Blob([encryptedMetadataBundle], {type: "application/octet-stream"}),
    );

    formData.append("scope", "text_message");
  }

  const recipientsAddresses = _.uniq([_key.getAddress(), recipientAddress]);

  formData.append("recipientsAddresses", JSON.stringify(recipientsAddresses));

  await jamApi.post("/message", formData, config);
};

export const getData = async messageId => {
  const config = {responseType: "arraybuffer"};

  const res = await jamApi.get(`/message/${messageId}`, config);

  const data = res.data;

  const readBundleData = await Bundle.loadBundle(data);

  const decryptedData = await readBundleData.getData(_key, "data");

  return decryptedData;
};

export const getMetaData = async messageId => {
  const config = {responseType: "arraybuffer"};

  try {
    const res = await jamApi.get(`/message/${messageId}/metadata`, config);

    const encryptedMetadata = res.data;

    const readBundleMetadata = await Bundle.loadBundle(encryptedMetadata);

    const decryptedMetadata = ab2str(await readBundleMetadata.getData(_key, "metadata"));

    const metadata = JSON.parse(decryptedMetadata);
    if (metadata.reference) metadata.reference = decodeURI(metadata.reference);
    if (metadata.name) metadata.name = decodeURI(metadata.name);

    return {metadata, encryptedMetadata: ab2b64(encryptedMetadata)};
  } catch (error) {
    return null;
  }
};

export const decryptMetadata = async b64EncryptedMetadata => {
  const encryptedMetadata = b642ab(b64EncryptedMetadata);

  const readBundleMetadata = await Bundle.loadBundle(encryptedMetadata);

  const decryptedMetadata = ab2str(await readBundleMetadata.getData(_key, "metadata"));

  const metadata = JSON.parse(decryptedMetadata);
  if (metadata.reference) metadata.reference = decodeURI(metadata.reference);
  if (metadata.name) metadata.name = decodeURI(metadata.name);

  return {metadata};
};

export const getMessages = async query => {
  const config = {params: query};

  const res = await jamApi.get("/message/list", config);

  return res.data;
};

export const deleteMessage = messageId => jamApi.delete(`/message/${messageId}`);

export const saveStorageData = async ({label, data}) => {
  const formData = new FormData();
  const config = {headers: {"content-type": `multipart/form-data; boundary=${formData._boundary}`}};

  const bigBundle = await Bundle.createBundle(_key, data);

  const encryptedDataBundle = await bigBundle.saveBundle();

  formData.append(
    "file",
    new Blob([encryptedDataBundle], {type: "application/octet-stream"}),
  );

  formData.append("label", label);

  await jamApi.post("/store", formData, config);
};

export const getStorageData = async label => {
  try {
    const res = await jamApi.get("/store/data", {
      params: {label},
      responseType: "arraybuffer",
    });

    const encryptedData = res.data;

    const readBundleData = await Bundle.loadBundle(encryptedData);

    const decryptedData = await readBundleData.getData(_key);

    return decryptedData;
  } catch (error) {
    return null;
  }
};

export default {
  setTokenKey,
  removeTokenKey,
  signinWithKey,
  getPublicKey,
  getFileBuffer,
  sendMessage,
  getData,
  getMetaData,
  decryptMetadata,
  getMessages,
  deleteMessage,
  saveStorageData,
  getStorageData,
};
