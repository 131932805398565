import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const useStyles = makeStyles(styles);

// eslint-disable-next-line max-lines-per-function
const RegularButton = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    loading,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    outlined,
    ...rest
  } = props;

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.outlined]: outlined,
    [className]: className,
  });

  return (
    <Button {...rest} ref={ref} classes={muiClasses} className={btnClasses}>
      {
        loading ? <div className={classes.loading}>{children}</div> : children
      }
      {
        loading && (
          <CircularProgress
            className={classes.iconLoading}
            // eslint-disable-next-line no-magic-numbers
            size={size === "lg" ? 24 : 18}
            disableShrink
          />
        )
      }
    </Button>
  );
});

RegularButton.displayName = "RegularButton";

RegularButton.defaultProps = {
  color: "primary",
  size: "sm",
  simple: false,
  loading: false,
  round: false,
  fullWidth: false,
  disabled: false,
  block: false,
  link: false,
  justIcon: false,
  className: "",
  muiClasses: {},
  outlined: false,
  children: null,
};

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  loading: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  outlined: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node,
};

export default RegularButton;
