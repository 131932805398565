import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth";
import en from "./i18n/en";
import fr from "./i18n/fr";

i18next.addResourceBundle("en", "compose", en);
i18next.addResourceBundle("fr", "compose", fr);

const ComposeConfig = {
  settings: {layout: "admin"},
  auth: authRoles.user,
  routes: [
    {
      exact: true,
      path: "/compose",
      component: React.lazy(() => import("./compose")),
    },
  ],
};

export default ComposeConfig;
