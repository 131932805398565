import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

const useStyles = makeStyles(styles);

const AuthLayout = props => {
  const {...rest} = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
  });

  return (
    <div>
      <AuthNavbar brandText="JAM Toast" {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage}>
          {props.children}
          <Footer white />
        </div>
      </div>
    </div>
  );
};

AuthLayout.displayName = "AuthLayout";

AuthLayout.propTypes = {children: PropTypes.node.isRequired};

export default AuthLayout;
