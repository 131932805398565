import * as Actions from "../actions";

const initialState = () => ({
  version: 1,
  addresses: [],
});

// eslint-disable-next-line default-param-last
const addressBookReducer = (state = initialState(), action) => {
  switch (action.type) {
  case Actions.ADDRESSBOOK_SET_ADDRESSES:
    return {
      ...state,
      addresses: action.payload,
    };
  case Actions.ADDRESSBOOK_IMPORT_ADDRESSBOOK:
    return action.payload;
  default:
    return state;
  }
};

export default addressBookReducer;
