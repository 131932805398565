import _history from "@history";
import _ from "lodash";
import jwtService from "app/services/jwt";
import {resetCache} from "app/main/inbox/store/actions";

export const SET_USER_DATA = "[USER] SET DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";

/**
 * Update User Data
 */
// eslint-disable-next-line no-unused-vars
const updateUserData = (user, dispatch) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      // dispatch(MessageActions.showMessage({ message: "User data saved with api" }));
    })
    // eslint-disable-next-line no-unused-vars
    .catch(error => {
      // dispatch(MessageActions.showMessage({ message: error.message }));
    });
};

/**
 * Set User Data
 */
export const setUserData = user => dispatch => {
  dispatch({
    type: SET_USER_DATA,
    payload: user,
  });
};

/**
 * Update User Settings
 */
export const updateUserSettings = settings => (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, {data: {settings}});

  updateUserData(user, dispatch);

  return dispatch(setUserData(user));
};

/**
 * Update User Shortcuts
 */
export const updateUserShortcuts = shortcuts => (dispatch, getState) => {
  const {user} = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  updateUserData(newUser, dispatch);

  return dispatch(setUserData(newUser));
};

/**
 * Remove User Data
 */
export const removeUserData = () => ({type: REMOVE_USER_DATA});

/**
 * Logout
 */
export const logoutUser = () => (dispatch, getState) => {
  const {user} = getState().auth;

  if (!user.role || user.role.length === 0) {
      // is guest
    return null;
  }

  dispatch(resetCache());

  jwtService.logout();

  _history.push({pathname: "/"});

  return dispatch({type: USER_LOGGED_OUT});
};
