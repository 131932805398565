import * as Actions from "../actions";

const initialState = () => ({
  success: false,
  error: null,
  data: {
    recipientEmail: "",
    message: "",
    reference: "",
  },
  sending: false,
});

// eslint-disable-next-line default-param-last
const invitationReducer = (state = initialState(), action) => {
  switch (action.type) {
  case Actions.INVITATION_SENDING:
    return {
      ...state,
      sending: true,
    };
  case Actions.INVITATION_ERROR:
    return {
      ...state,
      sending: false,
      error: action.payload,
    };
  case Actions.INVITATION_SUCCESS:
    return {
      ...state,
      sending: false,
      error: null,
      success: true,
    };
  case Actions.INVITATION_SET_DATA:
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
    };
  case Actions.INVITATION_RESET:
    return initialState();
  default:
    return state;
  }
};

export default invitationReducer;
