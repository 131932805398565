import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
// import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

import Button from "components/CustomButtons/Button.js";
import Person from "@material-ui/icons/Person";

import classNames from "classnames";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import * as Actions from "app/auth/store/actions";
import * as InboxActions from "app/main/inbox/store/actions";
import * as SettingsActions from "app/store/actions/settings.action";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(styles);

// eslint-disable-next-line max-lines-per-function
const HeaderLinks = props => {
  const dispatch = useDispatch();
  const {t} = useTranslation("common");

  const user = useSelector(({auth}) => auth.user);

  const [openProfile, setOpenProfile] = React.useState(null);

  const handleENClicked = useCallback(() => {
    dispatch(SettingsActions.setLanguage("EN"));
    setOpenProfile(null);
  }, [dispatch]);

  const handleFRClicked = useCallback(() => {
    dispatch(SettingsActions.setLanguage("FR"));
    setOpenProfile(null);
  }, [dispatch]);

  const handleClickProfile = useCallback(evt => {
    if (openProfile && openProfile.contains(evt.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(evt.currentTarget);
    }
  }, [openProfile]);

  const handleCloseProfile = useCallback(() => {
    setOpenProfile(null);
  }, []);

  const handleSignoutClick = useCallback(() => {
    setOpenProfile(null);
    dispatch(InboxActions.reset());
    dispatch(Actions.logoutUser());
  }, [dispatch]);

  const classes = useStyles();
  const {rtlActive} = props;
  const dropdownItem = classNames(
    classes.dropdownItem,
    classes.primaryHover,
    {[classes.dropdownItemRTL]: rtlActive},
  );
  const wrapper = classNames({[classes.wrapperRTL]: rtlActive});
  const managerClasses = classNames({[classes.managerClasses]: true});

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{label: rtlActive ? classes.labelRTL : ""}}
        >
          <Person
            className={
              `${classes.headerLinksSvg
              } ${
                rtlActive
                  ? `${classes.links} ${classes.linksRTL}`
                  : classes.links}`
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              Profile
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({TransitionProps}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: "0 0 0"}}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      className={dropdownItem}
                    >
                      {user && user.data ? user.data.email : ""}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      name="english"
                      onClick={handleENClicked}
                      className={dropdownItem}
                    >
                      {t("English")}
                    </MenuItem>
                    <MenuItem
                      name="french"
                      onClick={handleFRClicked}
                      className={dropdownItem}
                    >
                      {t("French")}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleSignoutClick}
                      className={dropdownItem}
                    >
                      {t("Sign out")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

HeaderLinks.propTypes = {rtlActive: PropTypes.bool};
HeaderLinks.defaultProps = {rtlActive: false};
HeaderLinks.displayName = "HeaderLinks";

export default HeaderLinks;
