import * as Actions from "../actions";

const initialState = {
  success: false,
  error: null,
  email: "",
  password: "",
  confirmPassword: "",
  inviteData: {},
};

// eslint-disable-next-line default-param-last
const login = (state = initialState, action) => {
  switch (action.type) {
  case Actions.LOGIN_SUCCESS:
    return {
      ...state,
      error: null,
      email: "",
      password: "",
      confirmPassword: "",
      success: true,
    };
  case Actions.LOGIN_ERROR:
    return {
      ...state,
      success: false,
      error: action.payload,
    };
  case Actions.LOGIN_SET_DATA:
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
};

export default login;
