import * as Actions from "../actions";

const defaultPageSize = 5;

const initialState = () => ({
  error: null,
  inbox: {
    page: 0,
    pageSize: defaultPageSize,
    messages: [],
    orderBy: "date",
    total: 0,
    totalPages: 0,
  },
  fetching: false,
  loaded: false,
  unreadMessages: 0,
});

// eslint-disable-next-line default-param-last, max-lines-per-function
const inboxReducer = (state = initialState(), action) => {
  switch (action.type) {
  case Actions.INBOX_SET_INBOX_SEARCH:
    return {
      ...state,
      inbox: {
        ...state.inbox,
        ...action.payload,
      },
    };
  case Actions.INBOX_DELETE_MESSAGES:
    return {
      ...state,
      fetching: false,
      inbox: {
        ...state.inbox,
        messages: action.payload,
      },
    };
  case Actions.INBOX_FETCH_MESSAGES:
    return {
      ...state,
      fetching: false,
      loaded: true,
      inbox: {
        ...state.inbox,
        ...action.payload,
      },
    };
  case Actions.INBOX_FETCHING:
    return {
      ...state,
      fetching: true,
    };
  case Actions.INBOX_SET_UNREAD_MESSAGES:
    return {
      ...state,
      unreadMessages: action.payload,
    };
  case Actions.INBOX_ERROR:
    return {
      ...state,
      fetching: false,
      error: action.payload,
    };
  case Actions.INBOX_RESET:
    return initialState();
  default:
    return state;
  }
};

export default inboxReducer;
