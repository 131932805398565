/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Register": "S'enregistrer",
  "You have been invited to use JamToast application": "Vous avez été invité à utiliser l'application JamToast",
  "Please setup your password to start using JamToast": "Veuillez configurer votre mot de passe pour commencer à utiliser JamToast",
  "Email": "Mail",
  "Password": "Mot de passe",
  "Confirm password": "Confirmez le mot de passe",
  "Required fields": "Champs obligatoires",
  "GotoLogin": "Si vous êtes déjà inscrit, allez à <1>page de connexion</1>",
};

export default locale;
