/* eslint quote-props: ["error", "consistent"] */

const locale = {
  "Compose": "Composer",
  "Send message": "Envoyer le message",
  "Recipient email": "Email du destinataire",
  "Reference": "Sujet",
  "Send file": "Envoyer le fichier",
  "Send text message": "Envoyer un texto",
};

export default locale;
