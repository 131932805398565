import jwtDecode from "jwt-decode";
import axios from "axios";
import {setProvider} from "@keeex/crypto";
import browerProvider from "@keeex/crypto-provider-browser";
import {
  importKey,
  KeyType,
} from "@keeex/js-keys/lib/keys";

setProvider(browerProvider);

import _history from "@history";

import {EventEmitter} from "app/helpers/utils";
import apiConfig from "app/configs/api.config";

import keyService from "./key";
import jamToast from "./jamtoast";
import jam from "./jam";

const secondTicks = 1000;

class JwtService extends EventEmitter {
  init() {
    // this.setInterceptors();
    this.handleAuthentication();
  }

  handleAuthentication() {
    const accessTokens = this.getAccessToken();

    if (!accessTokens.jamtoastToken
      || !accessTokens.jamToken
      || !accessTokens.userKey) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(accessTokens.jamtoastToken)
    && this.isAuthTokenValid(accessTokens.jamToken)) {
      this.setSession(accessTokens);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async signInWithEmailAndPassword(email, password) {
    const res = await jamToast.login(email, password);

    if (res.publicKey) {
      // import key from Vault
      await keyService.getBlob(password, res.authToken, res.publicKey);
    } else {
      // genereate and register new key
      await keyService.generateKey();

      const publicKey = await keyService.getPublic();
      const emailSig = await keyService.sign(email);
      await jamToast.registerKey(publicKey, emailSig);

      // register key
      await keyService.register(password, res.authToken);
    }

    // sign in Jam service
    await jam.signinWithKey(keyService.key);

    const user = {
      data: {email, address: keyService.address},
      role: ["USER"],
      redirectUrl: "/",
    };

    return user;
  }

  async signInWithToken() {
    try {
      const res = await jamToast.getConfig();
      if (!res.data) {
        throw new Error("Failed to login with token.");
      }

      // this.setSession(res.data.token);
      return {
        data: res.data,
        role: ["USER"],
      };
    } catch (error) {
      this.logout();
      _history.push("/auth/login");
      throw new Error("Failed to login with token.");
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateUserData(user) {
    return axios.post("/api/auth/user/update", {user});
  }

  // eslint-disable-next-line class-methods-use-this
  async setSession(accessTokens) {
    if (accessTokens) {
      jamToast.setToken(accessTokens.jamtoastToken);
      const key = await importKey(KeyType.bitcoin, {
        type: "json",
        value: JSON.parse(accessTokens.userKey),
      });

      jam.setTokenKey(accessTokens.jamToken, key);
    } else {
      jamToast.removeToken();
      jam.removeTokenKey();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    jamToast.removeToken();
    jam.removeTokenKey();
  }

  // eslint-disable-next-line class-methods-use-this
  isAuthTokenValid(accessToken) {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / secondTicks;
    if (decoded.exp < currentTime) {
      _history.push("/auth/login");
      return false;
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  getAccessToken() {
    return {
      jamtoastToken: window.localStorage.getItem(apiConfig.jamtoastTokenKey),
      jamToken: window.localStorage.getItem(apiConfig.jamTokenKey),
      userKey: window.localStorage.getItem(apiConfig.userKey),
    };
  }
}

const instance = new JwtService();

export default instance;
